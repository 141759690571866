.tabsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding: 0px 11.5px;
}

.tabBtn {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  color: var(--black-500);
  border-radius: 8px 8px 0px 0px;
  background-color: var(--black-100);
  width: max-content;
  border: none;
}

.tabBtn.active {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  color: var(--black-600);
}

.tabBtn svg {
  display: none;
}

.col2 {
  overflow: auto;
  min-height: 78vh;
}

.dataContainer {
  width: 100%;
  min-width: 800px;
  padding: 0px 8px;
}

@media screen and (min-width: 650px) {
  .tabsContainer {
    width: 224px;
    display: block;
    margin: 0;
    padding: 0;
  }

  .tabBtn {
    border: 1px solid var(--black-300);
    border-radius: 0px 8px 8px 0px;
    padding: 10px 30px;
    width: 224px;
  }

  .tabBtn svg {
    display: block;
  }

  .tabBtn:hover,
  .tabBtn.active {
    background: #f8f3fe;
    transition: background 0.7s ease;
  }
}

.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.table th {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.table td {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.filters {
  top: 30px;
  z-index: 1;
}

.filters > :last-child {
  border-bottom: 1px solid var(--black-400);
}

.filter {
  text-align: left;
  border: none;
  border-top: 1px solid var(--black-400);
}

.filter:hover {
  background-color: var(--black-300);
}

.mobileView {
  inset: 0 0 0 0;
  z-index: 100;
  overflow: hidden;
}

.mobileView img {
  width: 100px;
  height: auto;
  margin-bottom: 32px;
}

@media screen and (min-width: 650px) {
  .mobileView {
    display: none;
  }
}

@media screen and (min-width: 950px) and (min-height: 768px) {
  .table th {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  .table td {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  .footer {
    position: fixed;
    width: 100vw;
    left: 0px;
    bottom: 0px;
    z-index: 50;
  }
}

@media screen and (min-width: 1635px) and (min-height: 500px) {
  .footer {
    position: fixed;
    width: 100vw;
    left: 0px;
    bottom: 0px;
    z-index: 50;
  }
}

/* .footer2 {
  position: fixed;
  width: 100vw;
  left: 0px;
  bottom: 0px;
  z-index: 50;
} */
