.scroll-spy-item {
  color: var(--black-500);
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.scroll-spy-item.active-scroll-spy {
  border-left: 1px solid var(--black-600);
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-600);
}

.tab-header {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
