.container {
  background-color: rgba(0, 0, 0, 0.301);
  z-index: 11;
}
.modal{
  position: relative;

}
.innerDiv{
  position: absolute;
  top: 25px;
  right: 20px;
}
/* .modal {
  width: 300px;
} */
