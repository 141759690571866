@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--black-600);
  border-radius: 0.625rem;
}

:root {
  /* Font */
  --font: $font;
  /* Font Weight */
  --font-light: $font-light;
  --font-medium: $font-medium;
  --font-bold: $font-bold;
  /* Colors */
  --black-600: hsl(218deg, 54%, 20%);
  --black-500: hsl(218deg, 20%, 39%);
  --black-400: hsl(217deg, 13%, 74%);
  --black-300: hsl(228deg, 14%, 93%);
  --black-250: hsl(220deg, 16%, 96%);
  --black-200: hsl(210deg, 25%, 98%);
  --black-100: hsl(0deg, 0%, 100%);
  --purple-300: hsl(249deg, 49%, 39%);
  --purple-200: hsl(263deg, 87%, 60%);
  --purple-100: hsl(263deg, 86%, 92%);
  --purple-50: hsl(267deg, 85%, 97%);
  --skyblue-300: hsl(197deg, 100%, 33%);
  --skyblue-200: hsl(197deg, 98%, 53%);
  --skyblue-100: hsl(198deg, 100%, 91%);
  --skyblue-50: hsl(197deg, 100%, 97%);
  --green-300: hsl(180deg, 100%, 20%);
  --green-200: hsl(173deg, 81%, 42%);
  --green-100: hsl(173deg, 59%, 88%);
  --green-50: hsl(176deg, 58%, 95%);
  --yellow-300: hsl(33deg, 100%, 50%);
  --yellow-200: hsl(37deg, 100%, 54%);
  --yellow-100: hsl(45deg, 100%, 90%);
  --yellow-50: hsl(44deg, 100%, 95%);
  --red-300: hsl(12deg, 100%, 37%);
  --red-200: hsl(5deg, 71%, 49%);
  --red-100: hsl(5deg, 66%, 90%);
  --red-50: hsl(9deg, 64%, 96%);
  --blue-300: hsl(216deg, 92%, 34%);
  --blue-200: hsl(215deg, 100%, 50%);
  --blue-100: hsl(211deg, 66%, 92%);
  --blue-50: hsl(213deg, 65%, 97%);
  /* Breakpoints */
  --3xs: 320px;
  --2xs: 375px;
  --xs: 425px;
  --s: 600px;
  --m: 768px;
  --l: 1024px;
  --xl: 1200px;
  --2xl: 1440px;
  --3xl: 2560px;
  /* Animation */
  --animation-delay: $animation-delay;
  --animation-duration: $animation-duration;
}

body {
  overflow-x: hidden;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

button,
*[role='button'] {
  cursor: pointer;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  cursor: pointer;
}

input:focus,
textarea:focus,
button:focus {
  outline-color: var(--black-500);
  border-color: var(--black-500);
}

textarea {
  resize: vertical;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid hsl(228deg, 14%, 93%);
  margin: 1em 0;
  padding: 0;
}

.text-black-600 {
  color: hsl(218deg, 54%, 20%);
}

.text-black-500 {
  color: hsl(218deg, 20%, 39%);
}

.text-black-400 {
  color: hsl(217deg, 13%, 74%);
}

.text-black-300 {
  color: hsl(228deg, 14%, 93%);
}

.text-black-250 {
  color: hsl(220deg, 16%, 96%);
}

.text-black-200 {
  color: hsl(210deg, 25%, 98%);
}

.text-black-100 {
  color: hsl(0deg, 0%, 100%);
}

.text-purple-300 {
  color: hsl(249deg, 49%, 39%);
}

.text-purple-200 {
  color: hsl(263deg, 87%, 60%);
}

.text-purple-100 {
  color: hsl(263deg, 86%, 92%);
}

.text-purple-50 {
  color: hsl(267deg, 85%, 97%);
}

.text-skyblue-300 {
  color: hsl(197deg, 100%, 33%);
}

.text-skyblue-200 {
  color: hsl(197deg, 98%, 53%);
}

.text-skyblue-100 {
  color: hsl(198deg, 100%, 91%);
}

.text-skyblue-50 {
  color: hsl(197deg, 100%, 97%);
}

.text-green-300 {
  color: hsl(180deg, 100%, 20%);
}

.text-green-200 {
  color: hsl(173deg, 81%, 42%);
}

.text-green-100 {
  color: hsl(173deg, 59%, 88%);
}

.text-green-50 {
  color: hsl(176deg, 58%, 95%);
}

.text-yellow-300 {
  color: hsl(33deg, 100%, 50%);
}

.text-yellow-200 {
  color: hsl(37deg, 100%, 54%);
}

.text-yellow-100 {
  color: hsl(45deg, 100%, 90%);
}

.text-yellow-50 {
  color: hsl(44deg, 100%, 95%);
}

.text-red-300 {
  color: hsl(12deg, 100%, 37%);
}

.text-red-200 {
  color: hsl(5deg, 71%, 49%);
}

.text-red-100 {
  color: hsl(5deg, 66%, 90%);
}

.text-red-50 {
  color: hsl(9deg, 64%, 96%);
}

.text-blue-300 {
  color: hsl(216deg, 92%, 34%);
}

.text-blue-200 {
  color: hsl(215deg, 100%, 50%);
}

.text-blue-100 {
  color: hsl(211deg, 66%, 92%);
}

.text-blue-50 {
  color: hsl(213deg, 65%, 97%);
}

.bg-black-600 {
  background-color: hsl(218deg, 54%, 20%);
}

.bg-black-500 {
  background-color: hsl(218deg, 20%, 39%);
}

.bg-black-400 {
  background-color: hsl(217deg, 13%, 74%);
}

.bg-black-300 {
  background-color: hsl(228deg, 14%, 93%);
}

.bg-black-250 {
  background-color: hsl(220deg, 16%, 96%);
}

.bg-black-200 {
  background-color: hsl(210deg, 25%, 98%);
}

.bg-black-100 {
  background-color: hsl(0deg, 0%, 100%);
}

.bg-purple-300 {
  background-color: hsl(249deg, 49%, 39%);
}

.bg-purple-200 {
  background-color: hsl(263deg, 87%, 60%);
}

.bg-purple-100 {
  background-color: hsl(263deg, 86%, 92%);
}

.bg-purple-50 {
  background-color: hsl(267deg, 85%, 97%);
}

.bg-skyblue-300 {
  background-color: hsl(197deg, 100%, 33%);
}

.bg-skyblue-200 {
  background-color: hsl(197deg, 98%, 53%);
}

.bg-skyblue-100 {
  background-color: hsl(198deg, 100%, 91%);
}

.bg-skyblue-50 {
  background-color: hsl(197deg, 100%, 97%);
}

.bg-green-300 {
  background-color: hsl(180deg, 100%, 20%);
}

.bg-green-200 {
  background-color: hsl(173deg, 81%, 42%);
}

.bg-green-100 {
  background-color: hsl(173deg, 59%, 88%);
}

.bg-green-50 {
  background-color: hsl(176deg, 58%, 95%);
}

.bg-yellow-300 {
  background-color: hsl(33deg, 100%, 50%);
}

.bg-yellow-200 {
  background-color: hsl(37deg, 100%, 54%);
}

.bg-yellow-100 {
  background-color: hsl(45deg, 100%, 90%);
}

.bg-yellow-50 {
  background-color: hsl(44deg, 100%, 95%);
}

.bg-red-300 {
  background-color: hsl(12deg, 100%, 37%);
}

.bg-red-200 {
  background-color: hsl(5deg, 71%, 49%);
}

.bg-red-100 {
  background-color: hsl(5deg, 66%, 90%);
}

.bg-red-50 {
  background-color: hsl(9deg, 64%, 96%);
}

.bg-blue-300 {
  background-color: hsl(216deg, 92%, 34%);
}

.bg-blue-200 {
  background-color: hsl(215deg, 100%, 50%);
}

.bg-blue-100 {
  background-color: hsl(211deg, 66%, 92%);
}

.bg-blue-50 {
  background-color: hsl(213deg, 65%, 97%);
}

.p-0 {
  padding: 0px 0px;
}

.p-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-left-0 {
  padding-left: 0px;
}

.p-right-0 {
  padding-right: 0px;
}

.p-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.p-top-0 {
  padding-top: 0px;
}

.p-bottom-0 {
  padding-bottom: 0px;
}

.p-2 {
  padding: 2px 2px;
}

.p-x-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.p-left-2 {
  padding-left: 2px;
}

.p-right-2 {
  padding-right: 2px;
}

.p-y-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.p-top-2 {
  padding-top: 2px;
}

.p-bottom-2 {
  padding-bottom: 2px;
}

.p-4 {
  padding: 4px 4px;
}

.p-x-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.p-left-4 {
  padding-left: 4px;
}

.p-right-4 {
  padding-right: 4px;
}

.p-y-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.p-top-4 {
  padding-top: 4px;
}

.p-bottom-4 {
  padding-bottom: 4px;
}

.p-6 {
  padding: 6px 6px;
}

.p-x-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.p-left-6 {
  padding-left: 6px;
}

.p-right-6 {
  padding-right: 6px;
}

.p-y-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-top-6 {
  padding-top: 6px;
}

.p-bottom-6 {
  padding-bottom: 6px;
}

.p-8 {
  padding: 8px 8px;
}

.p-x-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.p-left-8 {
  padding-left: 8px;
}

.p-right-8 {
  padding-right: 8px;
}

.p-y-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-top-8 {
  padding-top: 8px;
}

.p-bottom-8 {
  padding-bottom: 8px;
}

.p-12 {
  padding: 12px 12px;
}

.p-x-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.p-left-12 {
  padding-left: 12px;
}

.p-right-12 {
  padding-right: 12px;
}

.p-y-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-top-12 {
  padding-top: 12px;
}

.p-bottom-12 {
  padding-bottom: 12px;
}

.p-16 {
  padding: 16px 16px;
}

.p-x-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-left-16 {
  padding-left: 16px;
}

.p-right-16 {
  padding-right: 16px;
}

.p-y-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-top-16 {
  padding-top: 16px;
}

.p-bottom-16 {
  padding-bottom: 16px;
}

.p-20 {
  padding: 20px 20px;
}

.p-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-left-20 {
  padding-left: 20px;
}

.p-right-20 {
  padding-right: 20px;
}

.p-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-bottom-20 {
  padding-bottom: 20px;
}

.p-24 {
  padding: 24px 24px;
}

.p-x-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.p-left-24 {
  padding-left: 24px;
}

.p-right-24 {
  padding-right: 24px;
}

.p-y-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.p-top-24 {
  padding-top: 24px;
}

.p-bottom-24 {
  padding-bottom: 24px;
}

.p-30 {
  padding: 30px 30px;
}

.p-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-left-30 {
  padding-left: 30px;
}

.p-right-30 {
  padding-right: 30px;
}

.p-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-top-30 {
  padding-top: 30px;
}

.p-bottom-30 {
  padding-bottom: 30px;
}

.p-32 {
  padding: 32px 32px;
}

.p-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.p-left-32 {
  padding-left: 32px;
}

.p-right-32 {
  padding-right: 32px;
}

.p-y-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.p-top-32 {
  padding-top: 32px;
}

.p-bottom-32 {
  padding-bottom: 32px;
}

.p-40 {
  padding: 40px 40px;
}

.p-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-left-40 {
  padding-left: 40px;
}

.p-right-40 {
  padding-right: 40px;
}

.p-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-top-40 {
  padding-top: 40px;
}

.p-bottom-40 {
  padding-bottom: 40px;
}

.p-44 {
  padding: 44px 44px;
}

.p-x-44 {
  padding-left: 44px;
  padding-right: 44px;
}

.p-left-44 {
  padding-left: 44px;
}

.p-right-44 {
  padding-right: 44px;
}

.p-y-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}

.p-top-44 {
  padding-top: 44px;
}

.p-bottom-44 {
  padding-bottom: 44px;
}

.p-48 {
  padding: 48px 48px;
}

.p-x-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.p-left-48 {
  padding-left: 48px;
}

.p-right-48 {
  padding-right: 48px;
}

.p-y-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.p-top-48 {
  padding-top: 48px;
}

.p-bottom-48 {
  padding-bottom: 48px;
}

.p-56 {
  padding: 56px 56px;
}

.p-x-56 {
  padding-left: 56px;
  padding-right: 56px;
}

.p-left-56 {
  padding-left: 56px;
}

.p-right-56 {
  padding-right: 56px;
}

.p-y-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.p-top-56 {
  padding-top: 56px;
}

.p-bottom-56 {
  padding-bottom: 56px;
}

.p-60 {
  padding: 60px 60px;
}

.p-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-left-60 {
  padding-left: 60px;
}

.p-right-60 {
  padding-right: 60px;
}

.p-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.p-top-60 {
  padding-top: 60px;
}

.p-bottom-60 {
  padding-bottom: 60px;
}

.p-64 {
  padding: 64px 64px;
}

.p-x-64 {
  padding-left: 64px;
  padding-right: 64px;
}

.p-left-64 {
  padding-left: 64px;
}

.p-right-64 {
  padding-right: 64px;
}

.p-y-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.p-top-64 {
  padding-top: 64px;
}

.p-bottom-64 {
  padding-bottom: 64px;
}

.p-120 {
  padding: 120px 120px;
}

.p-x-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-left-120 {
  padding-left: 120px;
}

.p-right-120 {
  padding-right: 120px;
}

.p-y-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.p-top-120 {
  padding-top: 120px;
}

.p-bottom-120 {
  padding-bottom: 120px;
}

.m-0 {
  margin: 0px 0px;
}

.m-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-left-0 {
  margin-left: 0px;
}

.m-right-0 {
  margin-right: 0px;
}

.m-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.m-top-0 {
  margin-top: 0px;
}

.m-bottom-0 {
  margin-bottom: 0px;
}

.m-2 {
  margin: 2px 2px;
}

.m-x-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.m-left-2 {
  margin-left: 2px;
}

.m-right-2 {
  margin-right: 2px;
}

.m-y-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.m-top-2 {
  margin-top: 2px;
}

.m-bottom-2 {
  margin-bottom: 2px;
}

.m-4 {
  margin: 4px 4px;
}

.m-x-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.m-left-4 {
  margin-left: 4px;
}

.m-right-4 {
  margin-right: 4px;
}

.m-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.m-top-4 {
  margin-top: 4px;
}

.m-bottom-4 {
  margin-bottom: 4px;
}

.m-6 {
  margin: 6px 6px;
}

.m-x-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.m-left-6 {
  margin-left: 6px;
}

.m-right-6 {
  margin-right: 6px;
}

.m-y-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.m-top-6 {
  margin-top: 6px;
}

.m-bottom-6 {
  margin-bottom: 6px;
}

.m-8 {
  margin: 8px 8px;
}

.m-x-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.m-left-8 {
  margin-left: 8px;
}

.m-right-8 {
  margin-right: 8px;
}

.m-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m-top-8 {
  margin-top: 8px;
}

.m-bottom-8 {
  margin-bottom: 8px;
}

.m-12 {
  margin: 12px 12px;
}

.m-x-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.m-left-12 {
  margin-left: 12px;
}

.m-right-12 {
  margin-right: 12px;
}

.m-y-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.m-top-12 {
  margin-top: 12px;
}

.m-bottom-12 {
  margin-bottom: 12px;
}

.m-16 {
  margin: 16px 16px;
}

.m-x-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.m-left-16 {
  margin-left: 16px;
}

.m-right-16 {
  margin-right: 16px;
}

.m-y-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.m-top-16 {
  margin-top: 16px;
}

.m-bottom-16 {
  margin-bottom: 16px;
}

.m-20 {
  margin: 20px 20px;
}

.m-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-left-20 {
  margin-left: 20px;
}

.m-right-20 {
  margin-right: 20px;
}

.m-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-24 {
  margin: 24px 24px;
}

.m-x-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.m-left-24 {
  margin-left: 24px;
}

.m-right-24 {
  margin-right: 24px;
}

.m-y-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.m-top-24 {
  margin-top: 24px;
}

.m-bottom-24 {
  margin-bottom: 24px;
}

.m-30 {
  margin: 30px 30px;
}

.m-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-right-30 {
  margin-right: 30px;
}

.m-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.m-32 {
  margin: 32px 32px;
}

.m-x-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.m-left-32 {
  margin-left: 32px;
}

.m-right-32 {
  margin-right: 32px;
}

.m-y-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.m-top-32 {
  margin-top: 32px;
}

.m-bottom-32 {
  margin-bottom: 32px;
}

.m-40 {
  margin: 40px 40px;
}

.m-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-left-40 {
  margin-left: 40px;
}

.m-right-40 {
  margin-right: 40px;
}

.m-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-44 {
  margin: 44px 44px;
}

.m-x-44 {
  margin-left: 44px;
  margin-right: 44px;
}

.m-left-44 {
  margin-left: 44px;
}

.m-right-44 {
  margin-right: 44px;
}

.m-y-44 {
  margin-top: 44px;
  margin-bottom: 44px;
}

.m-top-44 {
  margin-top: 44px;
}

.m-bottom-44 {
  margin-bottom: 44px;
}

.m-48 {
  margin: 48px 48px;
}

.m-x-48 {
  margin-left: 48px;
  margin-right: 48px;
}

.m-left-48 {
  margin-left: 48px;
}

.m-right-48 {
  margin-right: 48px;
}

.m-y-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.m-top-48 {
  margin-top: 48px;
}

.m-bottom-48 {
  margin-bottom: 48px;
}

.m-56 {
  margin: 56px 56px;
}

.m-x-56 {
  margin-left: 56px;
  margin-right: 56px;
}

.m-left-56 {
  margin-left: 56px;
}

.m-right-56 {
  margin-right: 56px;
}

.m-y-56 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.m-top-56 {
  margin-top: 56px;
}

.m-bottom-56 {
  margin-bottom: 56px;
}

.m-60 {
  margin: 60px 60px;
}

.m-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-left-60 {
  margin-left: 60px;
}

.m-right-60 {
  margin-right: 60px;
}

.m-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.m-top-60 {
  margin-top: 60px;
}

.m-bottom-60 {
  margin-bottom: 60px;
}

.m-64 {
  margin: 64px 64px;
}

.m-x-64 {
  margin-left: 64px;
  margin-right: 64px;
}

.m-left-64 {
  margin-left: 64px;
}

.m-right-64 {
  margin-right: 64px;
}

.m-y-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.m-top-64 {
  margin-top: 64px;
}

.m-bottom-64 {
  margin-bottom: 64px;
}

.m-120 {
  margin: 120px 120px;
}

.m-x-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-left-120 {
  margin-left: 120px;
}

.m-right-120 {
  margin-right: 120px;
}

.m-y-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.m-top-120 {
  margin-top: 120px;
}

.m-bottom-120 {
  margin-bottom: 120px;
}

.h-2xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.h-xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.h-s {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.h-m {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
}

.h-l {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

.h-xl {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.h-2xl {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
}

.h-3xl {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 72px;
}

.body-3xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}

.body-2xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.body-xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.body-s {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.body-m {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
}

.body-l {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 48px;
}

.btn-text-s {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.btn-text-xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.btn-text-2xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.btn-text-3xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
}

.link-text-s {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.link-text-xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.link-text-2xs {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-row-rev {
  flex-direction: row-reverse;
}

.flex-col-rev {
  flex-direction: column-reverse;
}

@media screen and (min-width: 320px) {
  .col-start-s-1 {
    grid-column-start: 1;
  }
}
@media screen and (min-width: 320px) {
  .col-start-s-2 {
    grid-column-start: 2;
  }
}
@media screen and (min-width: 320px) {
  .col-start-s-3 {
    grid-column-start: 3;
  }
}
@media screen and (min-width: 320px) {
  .col-start-s-4 {
    grid-column-start: 4;
  }
}
@media screen and (min-width: 320px) {
  .col-start-s-5 {
    grid-column-start: 5;
  }
}
@media screen and (min-width: 320px) {
  .col-end-s-1 {
    grid-column-end: 1;
  }
}
@media screen and (min-width: 320px) {
  .col-end-s-2 {
    grid-column-end: 2;
  }
}
@media screen and (min-width: 320px) {
  .col-end-s-3 {
    grid-column-end: 3;
  }
}
@media screen and (min-width: 320px) {
  .col-end-s-4 {
    grid-column-end: 4;
  }
}
@media screen and (min-width: 320px) {
  .col-end-s-5 {
    grid-column-end: 5;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-1 {
    grid-column-start: 1;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-2 {
    grid-column-start: 2;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-3 {
    grid-column-start: 3;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-4 {
    grid-column-start: 4;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-5 {
    grid-column-start: 5;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-6 {
    grid-column-start: 6;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-7 {
    grid-column-start: 7;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-8 {
    grid-column-start: 8;
  }
}
@media screen and (min-width: 768px) {
  .col-start-m-9 {
    grid-column-start: 9;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-1 {
    grid-column-end: 1;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-2 {
    grid-column-end: 2;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-3 {
    grid-column-end: 3;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-4 {
    grid-column-end: 4;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-5 {
    grid-column-end: 5;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-6 {
    grid-column-end: 6;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-7 {
    grid-column-end: 7;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-8 {
    grid-column-end: 8;
  }
}
@media screen and (min-width: 768px) {
  .col-end-m-9 {
    grid-column-end: 9;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-1 {
    grid-column-start: 1;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-2 {
    grid-column-start: 2;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-3 {
    grid-column-start: 3;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-4 {
    grid-column-start: 4;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-5 {
    grid-column-start: 5;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-6 {
    grid-column-start: 6;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-7 {
    grid-column-start: 7;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-8 {
    grid-column-start: 8;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-9 {
    grid-column-start: 9;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-10 {
    grid-column-start: 10;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-11 {
    grid-column-start: 11;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-12 {
    grid-column-start: 12;
  }
}
@media screen and (min-width: 1024px) {
  .col-start-l-13 {
    grid-column-start: 13;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-1 {
    grid-column-end: 1;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-2 {
    grid-column-end: 2;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-3 {
    grid-column-end: 3;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-4 {
    grid-column-end: 4;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-5 {
    grid-column-end: 5;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-6 {
    grid-column-end: 6;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-7 {
    grid-column-end: 7;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-8 {
    grid-column-end: 8;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-9 {
    grid-column-end: 9;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-10 {
    grid-column-end: 10;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-11 {
    grid-column-end: 11;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-12 {
    grid-column-end: 12;
  }
}
@media screen and (min-width: 1024px) {
  .col-end-l-13 {
    grid-column-end: 13;
  }
}
.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.btn {
  width: max-content;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  padding: 10px 24px;
  border-radius: 8px;
  font-family: 'Figtree', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  background: none;
}
.btn img,
.btn svg {
  width: 24px;
  height: 24px;
}
.btn.btn-lg {
  padding: 16px 24px;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.icon-btn {
  width: max-content;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  border-radius: 30px;
}
.icon-btn img,
.icon-btn svg {
  width: 24px;
  height: 24px;
}
.icon-btn.btn-lg {
  padding: 16px;
}

.btn-primary {
  background-color: hsl(215deg, 100%, 50%);
  color: hsl(0deg, 0%, 100%);
  transition: background 0.5s ease-out;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: hsl(228deg, 14%, 93%);
  color: hsl(218deg, 20%, 39%);
  cursor: not-allowed;
}
.btn-primary.disabled:hover,
.btn-primary.disabled:active,
.btn-primary:disabled:hover,
.btn-primary:disabled:active {
  background-color: hsl(228deg, 14%, 93%);
  color: hsl(218deg, 20%, 39%);
  cursor: not-allowed;
}
.btn-primary:hover,
.btn-primary:active {
  background-color: hsl(216deg, 92%, 34%);
  transition: background 0.5s ease-in;
}

.btn-secondary {
  border: 2px solid hsl(215deg, 100%, 50%);
  color: hsl(215deg, 100%, 50%);
  transition: background 0.3s ease-out;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: hsl(217deg, 13%, 74%);
  color: hsl(217deg, 13%, 74%);
  cursor: not-allowed;
}
.btn-secondary.disabled:hover,
.btn-secondary.disabled:active,
.btn-secondary:disabled:hover,
.btn-secondary:disabled:active {
  border-color: hsl(217deg, 13%, 74%);
  color: hsl(217deg, 13%, 74%);
  cursor: not-allowed;
}
.btn-secondary:hover,
.btn-secondary:active {
  background-color: hsl(211deg, 66%, 92%);
  transition: background 0.3s ease-in;
}

.icon-btn.btn-secondary {
  border-width: 1px;
}

.btn-tertiary {
  color: hsl(215deg, 100%, 50%);
  transition: color 0.3s ease-out;
  padding: 0;
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: hsl(218deg, 20%, 39%);
  cursor: not-allowed;
}
.btn-tertiary.disabled:hover,
.btn-tertiary.disabled:active,
.btn-tertiary:disabled:hover,
.btn-tertiary:disabled:active {
  color: hsl(218deg, 20%, 39%);
  cursor: not-allowed;
}
.btn-tertiary:hover,
.btn-tertiary:active {
  color: hsl(216deg, 92%, 34%);
  text-decoration: underline;
  transition: color 0.3s ease-in;
}

.link {
  width: max-content;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: hsl(215deg, 100%, 50%);
  cursor: pointer;
  transition: color 0.3s ease-out;
}
.link:hover,
.link:active,
.link:visited {
  color: hsl(216deg, 92%, 34%);
  transition: color 0.3s ease-in;
}

.link-s {
  font-size: 20px;
  line-height: 28px;
}

.link-xs {
  font-size: 16px;
  line-height: 24px;
}

.badge {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  width: max-content;
  height: 20px;
}

.badge-warning {
  background-color: hsl(45deg, 100%, 90%);
  color: hsl(33deg, 100%, 50%);
}

.badge-success {
  background-color: hsl(173deg, 81%, 42%);
  color: hsl(0deg, 0%, 100%);
}

.badge-process {
  background-color: hsl(173deg, 59%, 88%);
  color: hsl(180deg, 100%, 20%);
}

.badge-error {
  background-color: hsl(5deg, 71%, 49%);
  color: hsl(0deg, 0%, 100%);
}

.badge-purple {
  background-color: hsl(263deg, 87%, 60%);
  color: hsl(0deg, 0%, 100%);
}

.badge-black {
  background-color: hsl(218deg, 54%, 20%);
  color: hsl(0deg, 0%, 100%);
}

.badge-primary {
  background-color: hsl(215deg, 100%, 50%);
  color: hsl(0deg, 0%, 100%);
}

.badge-secondary {
  background-color: hsl(228deg, 14%, 93%);
  color: hsl(218deg, 54%, 20%);
}

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  border-radius: 0px 4px 4px 0px;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: max-content;
  display: inline-block;
}

.chip-warning {
  background-color: hsl(45deg, 100%, 90%);
  color: hsl(33deg, 100%, 50%);
}

.chip-success {
  background-color: hsl(173deg, 81%, 42%);
  color: hsl(0deg, 0%, 100%);
}

.chip-process {
  background-color: hsl(173deg, 59%, 88%);
  color: hsl(180deg, 100%, 20%);
}

.chip-error {
  background-color: hsl(5deg, 71%, 49%);
  color: hsl(0deg, 0%, 100%);
}

.chip-purple {
  background-color: hsl(263deg, 87%, 60%);
  color: hsl(0deg, 0%, 100%);
}

.chip-black {
  background-color: hsl(218deg, 54%, 20%);
  color: hsl(0deg, 0%, 100%);
}

.chip-primary {
  background-color: hsl(215deg, 100%, 50%);
  color: hsl(0deg, 0%, 100%);
}

.chip-secondary {
  background-color: hsl(228deg, 14%, 93%);
  color: hsl(218deg, 54%, 20%);
}

/* 
hamburger {
  padding: 16px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  height: 24px;
  margin-top: 4px;
  overflow: visible;
  display: flex;
  justify-content: right;
}
@media screen and (min-width: 1024px) {
  hamburger {
    display: none;
  }
}

hamburger:hover {
  opacity: 0.7;
}

hamburger.active:hover {
  opacity: 0.7;
}

hamburger.active .hamburger-inner,
hamburger.active .hamburger-inner::after,
hamburger.active .hamburger-inner::before {
  background-color: hsl(218deg, 54%, 20%);
}

.hamburger-box {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  height: 2px;
  width: 20px;
  background-color: hsl(218deg, 54%, 20%);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::after,
.hamburger-inner::before {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--slider .hamburger-inner {
  top: -7px;
}

.hamburger--slider .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 14px;
}

.hamburger--slider.active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--slider.active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 75ms 0.12s ease, opacity 75ms ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 75ms 0.12s ease,
    transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 75ms ease, opacity 75ms 0.12s ease;
}

.hamburger--squeeze.active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 75ms ease,
    transform 75ms 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.navigation {
  font-family: 'TT Commons', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  margin: 0;
  background: hsl(0deg, 0%, 100%);
  position: sticky;
  top: 0px;
  z-index: 10;
}

.nav-bar {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .nav-bar {
    display: flex;
    column-gap: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .nav-bar {
    column-gap: 32px;
    padding: 16px 128px;
  }
}
@media screen and (min-width: 1440px) {
  .nav-bar {
    column-gap: 32px;
  }
}

.nav-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-head .logo {
  width: 138px;
}
@media screen and (min-width: 1024px) {
  .nav-head .logo {
    width: 138px;
  }
}
@media screen and (min-width: 1024px) {
  .nav-head button {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .nav-head {
    width: max-content;
    padding: 0%;
  }
}

.nav-body {
  position: absolute;
  top: 79px;
  left: 0px;
  width: 100%;
  background: hsl(0deg, 0%, 100%);
  min-height: 90vh;
  display: none;
  overflow-y: auto;
}
.nav-body.active {
  position: static;
  display: block;
}
.nav-body > div {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}
@media screen and (min-width: 1024px) {
  .nav-body > div {
    width: max-content;
    display: flex;
    height: max-content;
  }
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1024px) {
  .nav-body {
    position: static;
    display: grid;
    grid-template-columns: 70% 30%;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    min-height: max-content;
    max-height: max-content;
  }
}

.nav-list hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid hsl(228deg, 14%, 93%);
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
.nav-list hr:last-of-type {
  margin-bottom: 0;
  margin-top: 8px;
}
@media screen and (min-width: 1024px) {
  .nav-list hr {
    display: none;
  }
}
.nav-list ul {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .nav-list ul {
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .nav-list ul {
    column-gap: 32px;
  }
}

.nav-item a,
.nav-item button {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: hsl(218deg, 54%, 20%);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  border: none;
  cursor: pointer;
  background: transparent;
}
.nav-item a:hover,
.nav-item button:hover {
  background: hsl(213deg, 65%, 97%);
  color: hsl(215deg, 100%, 50%);
}
.nav-item a .chevron-up,
.nav-item button .chevron-up {
  display: none;
}
@media screen and (min-width: 1024px) {
  .nav-item a,
  .nav-item button {
    gap: 4px;
    padding: 0%;
  }
  .nav-item a:hover,
  .nav-item button:hover {
    background: none;
  }
}
.nav-item .nav-content {
  display: none;
  padding: 24px;
}
@media screen and (min-width: 1024px) {
  .nav-item .nav-content {
    padding: 24px 128px;
  }
}
.nav-item .nav-content > div {
  margin-bottom: 30px;
}
@media screen and (min-width: 1024px) {
  .nav-item .nav-content > div {
    margin-bottom: 48px;
  }
}
.nav-item .nav-content h2 {
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #b3bac5;
  border-bottom: 1px solid #ebecf0;
  margin-bottom: 24px;
  padding-bottom: 8px;
  min-width: max-content;
  width: 100%;
}
/* @media screen and (min-width: 1024px) {
  .nav-item .nav-content h2 {
    margin: 0;
  }
} */
/* .nav-item .nav-content hr {
  display: none;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid hsl(228deg, 14%, 93%);
  margin: 12px 0px;
  padding: 0;
} */
/* @media screen and (min-width: 1024px) {
  .nav-item .nav-content hr {
    display: block;
  }
} */
/* .nav-item .nav-content ul {
  margin-left: -10px;
}
.nav-item .nav-content li {
  margin-bottom: 0px;
  width: 100%;
}
.nav-item .nav-content li a {
  display: flex;
  column-gap: 7px;
  font-family: 'TT Commons';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #172b4d;
  padding: 10px;
}
.nav-item .nav-content li a:hover {
  border: 1px solid #ebecf0;
  border-radius: 4px;
  background: #f4f5f7;
  transition: background 0.5s ease;
}
.nav-item .nav-content dl a {
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  padding: 4px 4px 4px 8px;
  margin-bottom: 24px;
}
.nav-item .nav-content dl a:hover {
  background: hsl(220deg, 16%, 96%);
  border: 1px solid hsl(228deg, 14%, 93%);
  border-radius: 4px;
}
.nav-item .nav-content dl a dt {
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #172b4d;
  margin-bottom: 8px;
}
.nav-item .nav-content dl a dd {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: hsl(218deg, 20%, 39%);
}
@media screen and (min-width: 1024px) {
  .nav-item .nav-content div:nth-of-type(2) ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .nav-item .nav-content div:nth-of-type(2) ul li:nth-of-type(odd) {
    grid-column: 1/3;
  }
  .nav-item .nav-content div:nth-of-type(2) ul li:nth-of-type(2n) {
    grid-column: 3/5;
  }
}
.nav-item.active a,
.nav-item.active button {
  background: hsl(213deg, 65%, 97%);
  color: hsl(215deg, 100%, 50%);
}
.nav-item.active a .chevron-up,
.nav-item.active button .chevron-up {
  display: block;
}
.nav-item.active a .chevron-down,
.nav-item.active button .chevron-down {
  display: none;
}
@media screen and (min-width: 1200px) {
  .nav-item.active a,
  .nav-item.active button {
    background: none;
  }
}
.nav-item.active .nav-content {
  display: block;
}
.nav-item.active .nav-content a {
  background: initial;
}
@media screen and (min-width: 1024px) {
  .nav-item.active .nav-content {
    position: absolute;
    height: max-content;
    width: 100%;
    left: 0;
    top: 80px;
    background: hsl(0deg, 0%, 100%);
    display: flex;
    column-gap: 24px;
    justify-content: space-between;
  }
  .nav-item.active .nav-content ul {
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-item .nav-content ul.social-list-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    row-gap: 0;
  }
  .nav-item .nav-content .social-list-2 li:nth-of-type(odd) {
    grid-column: 1/2;
  }
  .nav-item .nav-content .social-list-2 li:nth-of-type(2n) {
    grid-column: 2/3;
  }
}

.nav-search {
  background: hsl(220deg, 16%, 96%);
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 16px 24px;
}
.nav-search input {
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: hsl(218deg, 54%, 20%);
}
@media screen and (min-width: 768px) {
  .nav-search {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .nav-search {
    min-width: 300px;
    max-width: 550px;
    width: 100%;
    margin: 0%;
    padding: 0px 16px;
    max-height: 50px;
  }
}

.nav-icons-list {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-icons-list li {
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-icons-list a {
  width: 24px;
  height: 24px;
  color: hsl(218deg, 54%, 20%);
}
.nav-icons-list a:hover {
  color: hsl(215deg, 100%, 50%);
}
@media screen and (min-width: 600px) {
  .nav-icons-list {
    width: max-content;
    column-gap: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .nav-icons-list {
    margin: 0;
    padding: 0%;
  }
}

.nav-cta-btns {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.nav-cta-btns > a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.7s ease;
  border: 2px solid hsl(215deg, 100%, 50%);
  color: hsl(215deg, 100%, 50%);
  background: rgb(255, 255, 255);
}
.nav-cta-btns > a:hover {
  background-color: #ddeaf8;
  transition: background 0.3s ease-in;
} */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.display-grid {
  display: grid;
}

.display-inline-flex {
  display: inline-flex;
}

.display-inline-grid {
  display: inline-grid;
}

.width-full {
  width: 100%;
}

.width-half {
  width: 50%;
}

.width-1 {
  width: 100%;
}

.width-2 {
  width: 50%;
}

.width-3 {
  width: 33.33%;
}

.width-4 {
  width: 25%;
}

.width-5 {
  width: 20%;
}

.width-8 {
  width: 12.5%;
}

.width-10 {
  width: 10%;
}

.width-viewport {
  width: 100vw;
}

.width-max-content {
  width: max-content;
}

.height-full {
  height: 100%;
}

.height-half {
  height: 50%;
}

.height-viewport {
  height: 100vh;
}

.height-max-content {
  height: max-content;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.shadow-around {
  box-shadow: 1px 1px 6px hsla(0deg, 0%, 0%, 0.1);
}

.shadow-bottom {
  box-shadow: 0px 2px 4px hsla(0deg, 0%, 0%, 0.251);
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end;
}

.gap-0 {
  gap: 0px;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-30 {
  gap: 30px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.gap-44 {
  gap: 44px;
}

.gap-48 {
  gap: 48px;
}

.gap-56 {
  gap: 56px;
}

.gap-60 {
  gap: 60px;
}

.gap-64 {
  gap: 64px;
}

.gap-120 {
  gap: 120px;
}

.row-gap-0 {
  row-gap: 0px;
}

.row-gap-2 {
  row-gap: 2px;
}

.row-gap-4 {
  row-gap: 4px;
}

.row-gap-6 {
  row-gap: 6px;
}

.row-gap-8 {
  row-gap: 8px;
}

.row-gap-12 {
  row-gap: 12px;
}

.row-gap-16 {
  row-gap: 16px;
}

.row-gap-20 {
  row-gap: 20px;
}

.row-gap-24 {
  row-gap: 24px;
}

.row-gap-30 {
  row-gap: 30px;
}

.row-gap-32 {
  row-gap: 32px;
}

.row-gap-40 {
  row-gap: 40px;
}

.row-gap-44 {
  row-gap: 44px;
}

.row-gap-48 {
  row-gap: 48px;
}

.row-gap-56 {
  row-gap: 56px;
}

.row-gap-60 {
  row-gap: 60px;
}

.row-gap-64 {
  row-gap: 64px;
}

.row-gap-120 {
  row-gap: 120px;
}

.col-gap-0 {
  column-gap: 0px;
}

.col-gap-2 {
  column-gap: 2px;
}

.col-gap-4 {
  column-gap: 4px;
}

.col-gap-6 {
  column-gap: 6px;
}

.col-gap-8 {
  column-gap: 8px;
}

.col-gap-12 {
  column-gap: 12px;
}

.col-gap-16 {
  column-gap: 16px;
}

.col-gap-20 {
  column-gap: 20px;
}

.col-gap-24 {
  column-gap: 24px;
}

.col-gap-30 {
  column-gap: 30px;
}

.col-gap-32 {
  column-gap: 32px;
}

.col-gap-40 {
  column-gap: 40px;
}

.col-gap-44 {
  column-gap: 44px;
}

.col-gap-48 {
  column-gap: 48px;
}

.col-gap-56 {
  column-gap: 56px;
}

.col-gap-60 {
  column-gap: 60px;
}

.col-gap-64 {
  column-gap: 64px;
}

.col-gap-120 {
  column-gap: 120px;
}

.border {
  border: 1px solid hsl(218deg, 54%, 20%);
}

.border-top {
  border-top: 1px solid hsl(218deg, 54%, 20%);
}

.border-right {
  border-right: 1px solid hsl(218deg, 54%, 20%);
}

.border-bottom {
  border-bottom: 1px solid hsl(218deg, 54%, 20%);
}

.border-left {
  border-left: 1px solid hsl(218deg, 54%, 20%);
}

.border-none {
  border: none;
}

.border-black-600 {
  border-color: hsl(218deg, 54%, 20%);
}

.border-black-500 {
  border-color: hsl(218deg, 20%, 39%);
}

.border-black-400 {
  border-color: hsl(217deg, 13%, 74%);
}

.border-black-300 {
  border-color: hsl(228deg, 14%, 93%);
}

.border-black-250 {
  border-color: hsl(220deg, 16%, 96%);
}

.border-black-200 {
  border-color: hsl(210deg, 25%, 98%);
}

.border-black-100 {
  border-color: hsl(0deg, 0%, 100%);
}

.border-purple-300 {
  border-color: hsl(249deg, 49%, 39%);
}

.border-purple-200 {
  border-color: hsl(263deg, 87%, 60%);
}

.border-purple-100 {
  border-color: hsl(263deg, 86%, 92%);
}

.border-purple-50 {
  border-color: hsl(267deg, 85%, 97%);
}

.border-skyblue-300 {
  border-color: hsl(197deg, 100%, 33%);
}

.border-skyblue-200 {
  border-color: hsl(197deg, 98%, 53%);
}

.border-skyblue-100 {
  border-color: hsl(198deg, 100%, 91%);
}

.border-skyblue-50 {
  border-color: hsl(197deg, 100%, 97%);
}

.border-green-300 {
  border-color: hsl(180deg, 100%, 20%);
}

.border-green-200 {
  border-color: hsl(173deg, 81%, 42%);
}

.border-green-100 {
  border-color: hsl(173deg, 59%, 88%);
}

.border-green-50 {
  border-color: hsl(176deg, 58%, 95%);
}

.border-yellow-300 {
  border-color: hsl(33deg, 100%, 50%);
}

.border-yellow-200 {
  border-color: hsl(37deg, 100%, 54%);
}

.border-yellow-100 {
  border-color: hsl(45deg, 100%, 90%);
}

.border-yellow-50 {
  border-color: hsl(44deg, 100%, 95%);
}

.border-red-300 {
  border-color: hsl(12deg, 100%, 37%);
}

.border-red-200 {
  border-color: hsl(5deg, 71%, 49%);
}

.border-red-100 {
  border-color: hsl(5deg, 66%, 90%);
}

.border-red-50 {
  border-color: hsl(9deg, 64%, 96%);
}

.border-blue-300 {
  border-color: hsl(216deg, 92%, 34%);
}

.border-blue-200 {
  border-color: hsl(215deg, 100%, 50%);
}

.border-blue-100 {
  border-color: hsl(211deg, 66%, 92%);
}

.border-blue-50 {
  border-color: hsl(213deg, 65%, 97%);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-rounded {
  border-radius: 50%;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-32 {
  border-radius: 32px;
}
