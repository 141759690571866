.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(30px, auto);
  grid-gap: 15px;
  align-items: center;
  margin: 0px 16px;
}
@media screen and (min-width: 37.5rem) {
  .grid {
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 25px;
    margin: 0px 32px;
  }
}
@media screen and (min-width: 48rem) {
  .grid {
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 25px;
    margin: 0px 60px;
  }
}
@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
    margin: 0px 60px;
  }
}
@media screen and (min-width: 75rem) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
    margin: 0px 128px;
  }
}

ul {
  margin: 0;
  padding: 0;
}

.empty-link {
  position: relative;
}

.empty-link:hover::after {
  content: 'Coming Soon';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8509803922);
}

.hamburger {
  grid-column: 8/9;
  justify-self: right;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
}

header {
  font-family: 'Figtree', sans-serif;
  margin: 0;
  background: transparent;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 100;
  box-sizing: border-box;
  background: var(--black-100);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.mobile-nav {
  width: 100%;
  text-align: left;
}
@media screen and (min-width: 1023px) {
  .mobile-nav {
    display: none;
  }
}
.mobile-nav .nav-head {
  padding: 16px 24px;
  margin: 0;
}
.mobile-nav .nav-head .logo {
  grid-column: 1/8;
  display: block;
  width: 120px;
  height: 48px;
}
.mobile-nav .nav-head .logo img {
  height: 48px;
}
.mobile-nav .nav-head .logo .color-logo {
  display: none;
}
.mobile-nav .navigation {
  background: #ffffff;
  position: absolute;
  height: 93vh;
  width: 100%;
  top: 80px;
  display: none;
  overflow: auto;
}
.mobile-nav .navigation hr {
  border: none;
  background-color: transparent;
  color: transparent;
  border-top: 1px solid #ebecf0;
  margin: 0;
}
.mobile-nav .navigation .nav-item {
  grid-column: 1/5;
}
.mobile-nav .navigation .nav-item button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  font-style: inherit;
  font-family: inherit;
  font-size: inherit;
  padding: 16px 24px;
  color: #172b4d;
}
@media screen and (min-width: 37.5rem) {
  .mobile-nav .navigation .nav-item button {
    padding: 16px 32px;
  }
}
@media screen and (min-width: 48rem) {
  .mobile-nav .navigation .nav-item button {
    padding: 24px 60px;
  }
}
.mobile-nav .navigation .nav-item button span {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.mobile-nav .navigation .nav-item button .chevron {
  width: 20px;
  height: 20px;
}

.mobile-nav .navigation .nav-item button:hover {
  background: #f1f6fc;
  color: #006cff;
}
.mobile-nav .navigation .nav-item button.active {
  background: #f1f6fc;
  color: #006cff;
}
.mobile-nav .navigation .nav-item .nav-content {
  display: none;
  padding: 24px;
  width: 100%;
}
@media screen and (min-width: 37.5rem) {
  .mobile-nav .navigation .nav-item .nav-content {
    padding: 24px 32px;
  }
}
@media screen and (min-width: 48rem) {
  .mobile-nav .navigation .nav-item .nav-content {
    padding: 24px 60px;
  }
}
.mobile-nav .navigation .nav-item .nav-content div {
  margin-bottom: 30px;
}
.mobile-nav .navigation .nav-item .nav-content div:last-of-type {
  margin-bottom: 0%;
}
.mobile-nav .navigation .nav-item .nav-content h2 {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #b3bac5;
  margin-bottom: 24px;
  border-bottom: 1px solid #ebecf0;
  padding-bottom: 8px;
}
.mobile-nav .navigation .nav-item .nav-content ul {
  margin-left: -10px;
}
.mobile-nav .navigation .nav-item .nav-content ul li a {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #172b4d;
  padding: 10px;
}
.mobile-nav .navigation .nav-item .nav-content ul li a:hover {
  border: 1px solid #ebecf0;
  border-radius: 4px;
  background: #f4f5f7;
  transition: background 0.5s ease;
}
.mobile-nav .navigation .nav-item .nav-content dl {
  margin-left: -10px;
}
.mobile-nav .navigation .nav-item .nav-content dl a {
  display: block;
  padding: 10px;
  margin-bottom: 12px;
}
.mobile-nav .navigation .nav-item .nav-content dl a dt {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #172b4d;
  margin-bottom: 0.5rem;
}
.mobile-nav .navigation .nav-item .nav-content dl a dd {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #505f79;
}
.mobile-nav .navigation .nav-item .nav-content dl a:hover {
  border: 1px solid #ebecf0;
  border-radius: 4px;
  background: #f4f5f7;
  transition: background 0.5s ease;
}
.mobile-nav .navigation .nav-item .nav-content .product-card {
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
}
.mobile-nav .navigation .nav-item .nav-content .product-card:last-of-type {
  margin-bottom: 0%;
}
.mobile-nav .navigation .nav-item .nav-content .product-card .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #172b4d;
}
.mobile-nav .navigation .nav-item .nav-content .product-card .logo a {
  color: inherit;
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: max-content;
}
.mobile-nav .navigation .nav-item .nav-content .product-card .logo a span {
  display: block;
  width: max-content;
}
.mobile-nav .navigation .nav-item .nav-content .product-card a,
.mobile-nav .navigation .nav-item .nav-content .product-card img {
  display: block;
  width: 120px;
  margin-bottom: 24px;
}
.mobile-nav .navigation .nav-item .nav-content .product-card ul {
  margin: 0;
  margin-left: -10px;
}
.mobile-nav .navigation .nav-item .nav-content .product-card ul li {
  margin: 0;
}
.mobile-nav .navigation .nav-item .nav-content .product-card ul li a {
  display: block;
  width: 100%;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #172b4d;
  margin: 0;
}
.mobile-nav .navigation .nav-item .nav-content .product-card div {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #505f79;
}
.mobile-nav .navigation .nav-item .nav-content .express-protocol {
  background: #f8f3fe;
}
.mobile-nav .navigation .nav-item .nav-content .unity-market {
  background: #fff8e5;
}
.mobile-nav .navigation .nav-item .nav-content .aconomy {
  background: #ecfaf9;
}
.mobile-nav .navigation .nav-item .nav-content .social-list-2 {
  margin: 0;
  row-gap: 0;
}
.mobile-nav .navigation .nav-item .nav-content .social-list-2 li {
  margin: 0;
}
.mobile-nav
  .navigation
  .nav-item
  .nav-content
  .social-list-2
  li:nth-of-type(odd) {
  grid-column: 1/3;
}
.mobile-nav
  .navigation
  .nav-item
  .nav-content
  .social-list-2
  li:nth-of-type(even) {
  grid-column: 3/5;
}
.mobile-nav .navigation .nav-item .nav-content.active {
  display: block;
}
.mobile-nav .navigation .social-media {
  padding: 24px 0px;
  row-gap: 0px;
}
.mobile-nav .navigation .social-media > * {
  grid-column: 1/5;
}
.mobile-nav .navigation .social-media div {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #172b4d;
  margin-bottom: 1rem;
}
.mobile-nav .navigation .social-media .social-list {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 0;
  padding: 0;
}
.mobile-nav .navigation .social-media .social-list .social-icon a {
  color: #172b4d;
  display: block;
  width: 24px;
  height: 24px;
}
.mobile-nav .navigation .social-media .social-list .social-icon a:hover {
  color: #006cff;
}
.mobile-nav .navigation .mobile-btn-container {
  margin: 0px 16px;
  padding: 24px 0px;
}
.mobile-nav .navigation .mobile-btn-container .account-details {
  margin-top: 16px;
}
@media screen and (min-width: 400px) {
  .mobile-nav .navigation .mobile-btn-container {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
  .mobile-nav .navigation .mobile-btn-container .account-details {
    margin: 0;
  }
}
.mobile-nav .navigation.active {
  display: block;
}

.desktop-nav {
  padding: 16px 128px;
  margin: 0;
}
@media screen and (max-width: 1023px) {
  .desktop-nav {
    display: none;
  }
}
.desktop-nav .nav-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  grid-column: 1/4;
}
.desktop-nav .nav-head .logo {
  display: block;
  width: 120px;
  height: 48px;
}
.desktop-nav .nav-head .logo img {
  height: 48px;
}
.desktop-nav .nav-head .logo .color-logo {
  display: none;
}
.desktop-nav .navigation {
  grid-column: 4/10;
}
.desktop-nav .navigation .nav-list {
  display: flex;
  justify-content: center;
  column-gap: 24px;
}
.desktop-nav .navigation .nav-item button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  color: #172b4d;
}
.desktop-nav .navigation .nav-item button span {
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.desktop-nav .navigation .nav-item button .chevron {
  width: 20px;
  height: 20px;
}
.desktop-nav .navigation .nav-item button:hover {
  color: #006cff;
}
.desktop-nav .navigation .nav-item button.active {
  color: #006cff;
}
.desktop-nav .navigation .nav-item .nav-content {
  display: none;
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  background-color: #ffffff;
  margin: 10px 0px;
  padding: 24px 128px;
  column-gap: 50px;
  text-align: left;
  box-shadow: 0px 5px 6px rgba(58, 58, 58, 0.1);
}
@media screen and (min-width: 37.5rem) {
  .desktop-nav .navigation .nav-item .nav-content {
    padding: 24px 32px;
  }
}
@media screen and (min-width: 48rem) {
  .desktop-nav .navigation .nav-item .nav-content {
    padding: 24px 60px;
  }
}
@media screen and (min-width: 1024px) {
  .desktop-nav .navigation .nav-item .nav-content {
    padding: 24px 128px;
  }
}
.desktop-nav .navigation .nav-item .nav-content div:nth-of-type(2) ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.desktop-nav
  .navigation
  .nav-item
  .nav-content
  div:nth-of-type(2)
  ul
  li:nth-of-type(odd) {
  grid-column: 1/3;
}
.desktop-nav
  .navigation
  .nav-item
  .nav-content
  div:nth-of-type(2)
  ul
  li:nth-of-type(even) {
  grid-column: 3/5;
}
.desktop-nav .navigation .nav-item .nav-content h2 {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #b3bac5;
  margin-bottom: 24px;
  border-bottom: 1px solid #ebecf0;
  padding-bottom: 8px;
  min-width: max-content;
  width: 100%;
}
.desktop-nav .navigation .nav-item .nav-content ul {
  margin-left: -10px;
}
.desktop-nav .navigation .nav-item .nav-content ul li a {
  display: flex;
  column-gap: 7px;
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #172b4d;
  padding: 10px;
}
.desktop-nav .navigation .nav-item .nav-content ul li a:hover {
  border: 1px solid #ebecf0;
  border-radius: 4px;
  background: #f4f5f7;
  transition: background 0.5s ease;
}
.desktop-nav .navigation .nav-item .nav-content dl {
  margin-left: -10px;
}
.desktop-nav .navigation .nav-item .nav-content dl a {
  display: block;
  padding: 10px;
  margin-bottom: 12px;
}
.desktop-nav .navigation .nav-item .nav-content dl a dt {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #172b4d;
  margin-bottom: 8px;
}
.desktop-nav .navigation .nav-item .nav-content dl a dd {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #505f79;
}
.desktop-nav .navigation .nav-item .nav-content dl a:hover {
  border: 1px solid #ebecf0;
  border-radius: 4px;
  background: #f4f5f7;
  transition: background 0.5s ease;
}
.desktop-nav .navigation .nav-item .nav-content .social-list-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  row-gap: 0;
}
.desktop-nav
  .navigation
  .nav-item
  .nav-content
  .social-list-2
  li:nth-of-type(odd) {
  grid-column: 1/2;
}
.desktop-nav
  .navigation
  .nav-item
  .nav-content
  .social-list-2
  li:nth-of-type(even) {
  grid-column: 2/3;
}
.desktop-nav .navigation .nav-item .nav-content.active {
  display: grid;
  grid-template-columns: 16% 54% 20%;
}
.desktop-nav .desktop-btn {
  grid-column: 10/13;
  justify-self: right;
  width: max-content;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.desktop-nav .desktop-btn .account-details {
  margin-top: 16px;
}
@media screen and (min-width: 1024px) {
  .desktop-nav .desktop-btn .account-details {
    margin-top: 0px;
  }
}
.desktop-nav.active {
  display: block;
}

header[stuck] .mobile-nav,
header[active] .mobile-nav {
  background-color: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}
header[stuck] .mobile-nav .nav-head .logo .white-logo,
header[active] .mobile-nav .nav-head .logo .white-logo {
  display: none;
}
header[stuck] .mobile-nav .nav-head .logo .color-logo,
header[active] .mobile-nav .nav-head .logo .color-logo {
  display: block;
}
header[stuck] .mobile-nav .hamburger .hamburger-box .hamburger-inner,
header[active] .mobile-nav .hamburger .hamburger-box .hamburger-inner {
  background-color: #172b4d;
}
header[stuck] .mobile-nav .hamburger .hamburger-box .hamburger-inner::before,
header[active] .mobile-nav .hamburger .hamburger-box .hamburger-inner::before {
  background-color: #172b4d;
}
header[stuck] .mobile-nav .hamburger .hamburger-box .hamburger-inner::after,
header[active] .mobile-nav .hamburger .hamburger-box .hamburger-inner::after {
  background-color: #172b4d;
}
header[stuck] .desktop-nav,
header[active] .desktop-nav {
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}
header[stuck] .desktop-nav .nav-head .logo .white-logo,
header[active] .desktop-nav .nav-head .logo .white-logo {
  display: none;
}
header[stuck] .desktop-nav .nav-head .logo .color-logo,
header[active] .desktop-nav .nav-head .logo .color-logo {
  display: block;
}
header[stuck] .desktop-nav .navigation .nav-item button,
header[active] .desktop-nav .navigation .nav-item button {
  color: #172b4d;
}
header[stuck] .desktop-nav .navigation .nav-item button:hover,
header[active] .desktop-nav .navigation .nav-item button:hover {
  color: #006cff;
}
header[stuck] .desktop-nav .navigation .nav-item button.active,
header[active] .desktop-nav .navigation .nav-item button.active {
  color: #006cff;
}
header[stuck] .desktop-nav .btn,
header[active] .desktop-nav .btn {
  border-color: #006cff;
  color: #006cff;
}

header[active] .mobile-nav,
header[active] .desktop-nav {
  box-shadow: none;
}
