.container {
  margin-top: 16px;
  position: relative;
}

.col1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-top: 0px;
}

.col2 {
  padding: 32px;
}

.audited {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;

  width: auto;
  height: 28px;

  /* background: var(--purple-200); */
  background-color: #EBECF0;
  border-radius: 24px;
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  /* color: var(--black-100); */
  color: #505F79;
  &>img{
    width:14px;
    height: 14px;
  }
}

.verticalNavContainer {
  display: none;
}

.collection {
  flex-wrap: wrap;
  row-gap: 16px;
}

.collection > div {
  width: 100%;
  min-width: 300px;
}

.btn {
  width: 100%;
  max-width: 200px;
}

@media screen and (min-width: 768px) {
  .container {
    margin-top: 56px;
  }

  .col1 {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    left: 0;
    z-index: 1;
  }

  .col2 {
    padding: 0;
    margin-left: 300px;
    margin-right: 115px;
  }

  .verticalNavContainer {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .col1 {
    left: 0;
  }

  .col2 {
    margin-left: 287px;
  }

  .imageUpload {
    display: grid;
    grid-template-columns: 28% 68%;
    column-gap: 32px;
  }

  .collection {
    flex-wrap: nowrap;
  }
}

.inputContainer{
  display: flex;
  column-gap: 32px;
  width: 100%;
  row-gap: 16px;
  @media(max-width:1035px){
    flex-wrap: wrap;
  }
}

.inputContainer > div {
width: 100%;
min-width: 300px;
}

.copyERC{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  column-gap:2px;
  &> span{
    border-radius: 8px;
    img{
      height: 18px;
      width:18px;
    }
  }
}

.loading{
  display: flex;
  justify-content: center;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main_div{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 80px;
}

.inner_div{
  display: flex;
  justify-content: space-between;
}
.outer_div{
  display: flex;
  flex-direction: column;
  &> span{
    color: #B3BAC5;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-right: 30px;
  }
}

.attachMedia{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  column-gap:20px;
  &> input[type="file"] {
    display: none;
  }
  &> label, button{
    margin:0;
    outline: none;
    /* display: flex; */
    background-color: #F4F5F7;
    width:152px;
    height: 44px;
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    gap: 4px;
    cursor: pointer;
  }

  button{
    border: 1px solid black;

    padding:10px ;
    display: flex;
    justify-content: center;
  }

  .success{
    border-color: rgb(103, 216, 88);
    color: rgb(82, 197, 67)
  }

  .disableInput{
    color:#cacbcb;
    cursor: no-drop;
  }

}

.succeed{
  /* margin-top: 20px; */
  display:flex;
  gap: 20px;
  align-items: center;
  &> span{
    font-size: 14px;

    &:first-of-type{
      display: flex;
      font-weight: 600 !important;
      color: #006CFF !important;
      align-items: center;
      gap: 5px;
  
    }
    &:last-of-type{
      font-weight:500 ;
      color: #505F79;

      a{
        color:#505F79;
        margin: 0 2.5px;
        text-decoration: underline;
      }
    }

    img{
      width:16px;
      height: 16px;
    }
  }
}

.label_tool{
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.tooltip{
  &> img{
    margin-top: 8px;
    width: 16px;
    height: 16px;
  }
  .tooltiptext{
    visibility: hidden;
    position: absolute;
    min-height: 80px;
    max-width: 180px;
    border-radius: 8px;
    padding:6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    background-color: rgb(232, 227, 227);
    color: #505F79;
  }
  
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.step{
  font-weight: 700;
font-size: 18px;
color:#505F79;
margin-bottom: 10px;
}

.btn_row{
  display: flex;
  justify-content: flex-start;
  column-gap: 30px;
}