.dropdownBtn {
  height: 24px;
  width: 24px;
}

.list {
  width: 225px;
}

.icon {
  width: 30px;
  height: 30px;
}

.button:hover,
.button:focus {
  background-color: #f3f4f6;
}
