.footer {
  padding: 16px;
  border-top: 1px solid var(--black-300);
  background-color: var(--black-100);
}

.copyright {
  margin-bottom: 28px;
}

.copyright p {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-500);
  margin-bottom: 8px;
}

.copyright a {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-600);
}

.copyright a:hover {
  color: var(--blue-200);
}

.love {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-600);
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.love img:nth-child(2) {
  height: 24px;
  width: 102px;
}

@media screen and (min-width: 600px) {
  .copyright {
    display: flex;
    text-align: left;
    column-gap: 24px;
  }

  .copyright p {
    margin-bottom: 0%;
  }

  .copyright a:first-of-type {
    margin: 0;
  }
}

@media screen and (min-width: 850px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .copyright {
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    padding: 16px 120px;
  }
}
