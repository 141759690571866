.container{
  width:380px;
  /* height: 280px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  & > h2{
    margin-bottom: 15px;
  }
  & > form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    &> button {
      margin-top: 20px;
    }
  }
}
.selectDiv{
  margin-top: 6px;
  border-radius: 8px;
  box-shadow: 1px 2px 2px 0px rgb(0 0 0 / 0.1);
  background-color: rgba(246, 246, 246, 0.596);
  padding: 4px  8px;
}
label{
  &:last-of-type{
    margin-top: 15px;
  }
}
.searchInput{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  &:focus-within{
    border: 2px solid rgb(100, 100, 100);
  }
  & > input {
    outline: none;
  }
}
.registered, .available, .invalid{
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  color: rgb(212, 170, 0);
  background-color: rgb(246, 236, 192);
  border-radius: 4px;
  letter-spacing: 0.5px;
}
.available{
  background-color: rgb(188, 255, 188);
  color: rgb(0, 197, 0);
}
.invalid{
  background-color: rgb(255, 190, 190);
  color: rgb(186, 3, 3);
}

.price{
  margin-top: 10px;
  font-weight: 600;
  color: rgb(105, 104, 104);
  font-size: 16px;
  &> span{
    font-weight: 900;
  }
}

.loading{
  display: flex;
  justify-content: center;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
