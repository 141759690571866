.value > div {
  border-radius: 8px 0px 0px 8px;
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.value > button {
  border-radius: 0px 8px 8px 0px;
  padding: 4px;
}

@media screen and (min-width: 768px) {
  .value > div {
    font-size: 18px;
    line-height: 28px;
  }

  .value > button {
    padding: 6px;
  }
}

.options {
  min-width: 150px;
  border-width: 0.5px;
  z-index: 2;
  top: 44px;
}

.options li:hover {
  background: hsl(220deg, 16%, 96%);
}

.options li:hover button {
  background-color: inherit;
}
